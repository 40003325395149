import { FragmentType, getFragmentData, graphql } from '~/resources/graphql'
import { computed, inject, InjectionKey, provide, Ref } from 'vue'
import type { ReturnType } from 'birpc'

export const UseStorefrontStorefrontFragment = graphql(`
    fragment UseStorefrontStorefrontFragment on Storefront {
        treeNavigation
        collectMoney
        profileAllowed
        allowGuestCheckout
        idleTimeout
        idleTimeoutMessage
        config(language: ENGLISH)
    }
`)

const useStorefrontInjectionKey: InjectionKey<
    ReturnType<typeof createStorefront>
> = Symbol('UseStorefrontInjectionKey')

export function createStorefront(
    storefront: Ref<FragmentType<typeof UseStorefrontStorefrontFragment>>
) {
    const storefrontData = computed(() =>
        getFragmentData(UseStorefrontStorefrontFragment, storefront.value)
    )
    provide(useStorefrontInjectionKey, storefrontData)
    return storefrontData
}

export function useStorefront() {
    const result = inject(useStorefrontInjectionKey)
    if (result === undefined) {
        throw new Error()
    }

    return result
}
